import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
} from "axios";
import { Notify } from "vant";

export class Request {
  public static axiosInstance: AxiosInstance;

  public static init(): AxiosStatic {
    // 创建axios实例
    this.axiosInstance = axios.create({
      baseURL: "//msapi.513dvr.com",
      timeout: 5000, // request timeout
    });
    // 初始化拦截器
    this.initInterceptors();
    return axios;
  }

  public static initInterceptors(): void {
    // 设置请求头
    // this.axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

    /**
     * 请求拦截器
     */
    this.axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // const token = localStorage.getItem("ACCESS_TOKEN");
        // if (token) {
        //   config.headers.Authorization = "Bearer " + token;
        // }
        return config;
      },
      (error: any) => {
        console.error(error);
      }
    );

    /**
     * 响应拦截器
     */
    this.axiosInstance.interceptors.response.use(
      // 请求成功
      (response: AxiosResponse) => {
        if (response.status === 200) {
          return response;
        } else {
          Request.errorHandle(response);
          return response;
        }
      },
      // 请求失败
      (error: any) => {
        const { response } = error;
        if (response) {
          // 请求已发出，但是不在2xx的范围
          Request.errorHandle(response);
          return Promise.reject(response.data);
        } else {
          // 处理断网的情况
          Notify({ type: "danger", message: "网络连接异常,请稍后再试!" });
        }
      }
    );
  }

  // http握手错误
  private static errorHandle(res: any): void {
    // 状态码判断
    switch (res.status) {
      case 401:
        Notify({ type: "danger", message: "权限不足" });
        break;
      case 403:
        Notify({ type: "danger", message: "权限已过期" });
        break;
      case 404:
        Notify({ type: "danger", message: "请求的资源不存在" });
        break;
      default:
        Notify({ type: "danger", message: "连接错误" });
    }
  }
}
