import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Icon, Lazyload } from "vant";
import "amfe-flexible";

import { Request } from "@/utils/axios";
import VueAxios from "vue-axios";

createApp(App)
  .use(router)
  .use(VueAxios, Request.init())
  .use(Icon)
  .use(Lazyload)
  .mount("#app");
