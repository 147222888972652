<template>
  <div class="bgmusic">
    <img
      class="bgm"
      :class="{ paused: !isBGM, show: isShow }"
      src="../assets/music.png"
      @click="closeBGM"
    />
    <audio id="bgmusic" :src="bgmUrl" autoplay loop style="display: none">
      您的浏览器不支持 audio 标签。
    </audio>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
export default defineComponent({
  name: "Bgm",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isBGM: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    var bgmUrl = "//513dvr.com/gcweb/bgm.mp3";

    // 是否打开音乐
    const closeBGM = () => {
      ctx.emit("closeBGM", !props.isBGM);
    };

    watch(
      () => props.isBGM,
      (newVal) => {
        let dom: any = document.getElementById("bgmusic");
        if (newVal) {
          dom.play();
        } else {
          dom.pause();
        }
      }
    );

    return {
      bgmUrl,
      closeBGM,
    };
  },
});
</script>
<style lang="scss">
.bgm {
  position: fixed;
  top: 55px;
  right: calc(((100% - 375px) / 2) + 20px);
  width: 36px;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  animation: rotate 4s linear infinite;
  -webkit-animation: rotate 4s linear infinite;
  z-index: 10;
  transition: all 0.5s;
}

.paused {
  animation-play-state: paused;
  -webkit-animation-play-state: paused; /* Safari 和 Chrome */
}

.show {
  right: -80px;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
