<template>
  <Bgmusic
    v-show="isTabbar"
    :isShow="bgmShow"
    :isBGM="isBGM"
    @closeBGM="(val) => (isBGM = val)"
  />
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component
        :is="Component"
        @touchBgmShow="touchBgmShow"
        @touchstart="touchstart"
        @BgmSwitch="BgmSwitch"
      />
    </keep-alive>
  </router-view>
  <Tabbar v-show="isTabbar" />
</template>
<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import Tabbar from "@/components/Tabbar.vue";
import Bgmusic from "@/components/Bgmusic.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { Tabbar, Bgmusic },
  setup() {
    // 是否显示导航栏，加个音乐logo
    var isTabbar = ref<boolean>(true);
    // 是否隐藏bgm
    const bgmShow = ref<boolean>(false);
    // 打开视频的时候关闭音乐
    const isBGM = ref<boolean>(true);

    // 案例页，横向滑动时隐藏音乐logo
    const touchBgmShow = (val: boolean) => {
      bgmShow.value = val;
    };

    // 监听路由
    const route = useRoute();
    watch(
      () => route.path,
      (val) => {
        val == "/search" ? (isTabbar.value = false) : (isTabbar.value = true);
      }
    );

    // ios端，滑动则触发背景音乐
    const touchstart = () => {
      setTimeout(() => {
        let dom: any = document.getElementById("bgmusic");
        if (!dom.currentTime) {
          dom.play();
        }
      }, 500);
    };

    // bgm开关
    const BgmSwitch = (val: boolean) => {
      isBGM.value = val;
      isTabbar.value = val;
    };

    return {
      isTabbar,
      bgmShow,
      isBGM,
      touchBgmShow,
      touchstart,
      BgmSwitch,
    };
  },
});
</script>
<style lang="scss">
@import url("./assets/flex.css");
@font-face {
  font-family: "DIN-Bold";
  src: url("./assets/DIN-Bold.otf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html,
body {
  height: 100%;
  background: #f1f1f1;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 2px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 2px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 2px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 auto;
}

.van-tabbar,
.van-nav-bar {
  // 超过高德地图的logo
  z-index: 170;
}

@media screen and (min-width: 750px) {
  html,
  body,
  #app {
    position: relative;
    width: 375px;
    margin: 0 calc((100% - 375px) / 2);
  }

  .van-tabbar,
  .van-nav-bar,
  .van-search {
    width: 375px !important;
    left: calc((100% - 375px) / 2) !important;
  }

  .case_tabs {
    width: 375px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    clip-path: inset(0 0 0 0) !important;
  }

  .bgm {
    right: calc(((100% - 375px) / 2) + 20px);
  }
}
</style>
