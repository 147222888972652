
import { defineComponent, ref, watch } from "vue";
import Tabbar from "@/components/Tabbar.vue";
import Bgmusic from "@/components/Bgmusic.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { Tabbar, Bgmusic },
  setup() {
    // 是否显示导航栏，加个音乐logo
    var isTabbar = ref<boolean>(true);
    // 是否隐藏bgm
    const bgmShow = ref<boolean>(false);
    // 打开视频的时候关闭音乐
    const isBGM = ref<boolean>(true);

    // 案例页，横向滑动时隐藏音乐logo
    const touchBgmShow = (val: boolean) => {
      bgmShow.value = val;
    };

    // 监听路由
    const route = useRoute();
    watch(
      () => route.path,
      (val) => {
        val == "/search" ? (isTabbar.value = false) : (isTabbar.value = true);
      }
    );

    // ios端，滑动则触发背景音乐
    const touchstart = () => {
      setTimeout(() => {
        let dom: any = document.getElementById("bgmusic");
        if (!dom.currentTime) {
          dom.play();
        }
      }, 500);
    };

    // bgm开关
    const BgmSwitch = (val: boolean) => {
      isBGM.value = val;
      isTabbar.value = val;
    };

    return {
      isTabbar,
      bgmShow,
      isBGM,
      touchBgmShow,
      touchstart,
      BgmSwitch,
    };
  },
});
