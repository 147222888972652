
import { defineComponent, watch } from "vue";
export default defineComponent({
  name: "Bgm",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isBGM: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    var bgmUrl = "//513dvr.com/gcweb/bgm.mp3";

    // 是否打开音乐
    const closeBGM = () => {
      ctx.emit("closeBGM", !props.isBGM);
    };

    watch(
      () => props.isBGM,
      (newVal) => {
        let dom: any = document.getElementById("bgmusic");
        if (newVal) {
          dom.play();
        } else {
          dom.pause();
        }
      }
    );

    return {
      bgmUrl,
      closeBGM,
    };
  },
});
