<template>
  <van-tabbar
    route
    v-model="active"
    active-color="#2BADFA"
    inactive-color="#C6CBDD"
  >
    <van-tabbar-item replace to="/home">
      首页
      <template #icon="props">
        <img :src="props.active ? home.active : home.inactive" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/case">
      案例
      <template #icon="props">
        <img :src="props.active ? cases.active : cases.inactive" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/about">
      我们
      <template #icon="props">
        <img :src="props.active ? us.active : us.inactive" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item @click="toWebsite">
      官网
      <template #icon="props">
        <img :src="props.active ? website.active : website.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, toRefs } from "vue";
import { Tabbar, TabbarItem } from "vant";
export default defineComponent({
  name: "Tabbar",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  setup() {
    // 前往官网
    const toWebsite = () => {
      window.open("//513dvr.com");
    };

    const state = reactive({
      home: {
        active: require("../assets/index-2.png"),
        inactive: require("../assets/index-1.png"),
      },
      cases: {
        active: require("../assets/case-2.png"),
        inactive: require("../assets/case-1.png"),
      },
      us: {
        active: require("../assets/us-2.png"),
        inactive: require("../assets/us-1.png"),
      },
      website: {
        active: require("../assets/website-2.png"),
        inactive: require("../assets/website-1.png"),
      },
    });

    const active = ref(0);

    return {
      toWebsite,
      active,
      ...toRefs(state),
    };
  },
});
</script>
